<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生客户分析</a-breadcrumb-item>
            <a-breadcrumb-item>总量分析</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-popover placement="bottom" trigger="click" v-model="visible">
                        <template slot="content">
                            <div class="more-search">
                            <a-form layout='inline'>
                                <a-form-item class="block-line" label="来源渠道">
                                    <a-tree-select
                                      v-model="paramsData.channel_id"
                                      :tree-data="parentChannels"
                                      tree-checkable
                                      allowClear
                                      treeNodeFilterProp="title"
                                      :maxTagCount='1'
                                      :maxTagTextLength='5'
                                      :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                      :show-checked-strategy="SHOW_ALL"
                                      placeholder="请选择来源渠道"
                                  />
                                </a-form-item>
                                <a-form-item class="block-line" label="所属校区">
                                  <a-select v-model="paramsData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                                      <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                  </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="添加人">
                                    <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.created_by' allowClear showSearch @search="handleSearch" placeholder="请选择添加人" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                            <div class="more-search__buttons">
                                <a-button type="dashed" @click="closeSearch">取消</a-button>
                                <a-button @click="reset">重置</a-button>
                                <a-button type="primary" @click="toScreen">搜索</a-button>
                            </div>
                            </div>
                        </template>
                        <a-button>更多<a-icon type="down" /></a-button>
                        </a-popover>
                    </a-form-item>
                    </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">

                    <a-col :lg="24" :xl="24">
                        <customerCount :loading ='loading'  :leads_data ='leads_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                        <sankey :loading ='loadingS' :link='link' :maxLength='maxLength' :sankey_title="sankey_title"  :sankey_data ='sankey_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    
                    <a-col :lg="24" :xl="12">
                      <LRanking title="校区客户新增总量" :rank_data="leads_studio" :loading="loadingC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    
                    <a-col :lg="24" :xl="12">
                      <LRanking title="市场添加客户总量" :rank_data="leads_created_by" :loading="loadingC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <LRanking title="渠道大类新增客户统计" :rank_data="channelData" :loading="loadingCC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <LRanking title="渠道细分新增客户统计" :rank_data="channelISData" :loading="loadingIS" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingCC' :item ='channelData' title="渠道大类新增客户比例" subtext='总量' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingIS' :item ='channelISData' title="渠道细分新增客户比例" subtext='总量' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                          <LRanking title="渠道大类签约总量" :rank_data="parent_channel" :loading="loadingAC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <LRanking title="细分渠道签约总量" :rank_data="sub_channel" :loading="loadingAC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingAC' :item ='parent_channel' title="渠道大类签约比例" subtext='总量' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingAC' :item ='sub_channel' title="细分渠道签约比例" subtext='总量' />
                    </a-col>
                    
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import customerCount from './customer_count'
import sankey from './sankey'
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'customer',
  components:{
    customerCount,
    statistics,
    sankey,
  },
  data() {
    return {
      visible:false,
      loading:false,
      loadingS:false,
      loadingC:false,
      loadingC:false,
      loadingSC:false,
      loadingIS:false,
      loadingSCS:false,
      leads_created_by:{},
      leads_studio:{},
      leads_data:{},
      sankey_data:[],
      sankey_title:'',
      parentChannels: [],
      parent_channel:{},
      sub_channel:{},
      channelData:{},
      channelSCData:{},
      channelISData:{},
      channelSCSData:{},
      link:[],
      counts:[],
      studios:[],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:undefined,
        filter_type:'week'
      },
      channelsId:[],
      maxLength:0,
      staffList:[]
    }
  },
  mixins: [ranges],
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStaff()
    this.getStudio()
    this.getSourceChannel()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      this.getCount(obj)
      this.getSankey(obj)
      this.getAmount(obj)
      this.getChannel(obj)
      this.getChannelSubChannel(obj)
      this.getChannelIndexStatis(obj)
      this.getChannelSubChannelStatis(obj)
      this.getAchievementChannel(obj)
      let res = await this.$store.dispatch('analysisCrmCustomerAction', obj)
      this.leads_data = res.data
      this.loading = false
    },
    async getSankey(obj) {
      this.loadingS = true
      let res = await this.$store.dispatch('analysisCrmCustomerSankeyAction', obj)
      this.sankey_data = res.data.rows
      this.sankey_title = res.data.title
      this.link = res.data.link
      let lengthObj = res.data.length
      let arr = [lengthObj.cc,lengthObj.channel,lengthObj.mkt,lengthObj.studio]
      this.maxLength = Math.max(...arr)
      this.loadingS = false
    },
    async getAmount(obj){
      this.loadingC = true
      let res = await this.$store.dispatch('analysisCrmCustomerAmountAction', obj)
      this.leads_created_by = res.data.created_by
      this.leads_studio = res.data.studio
      this.loadingC = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCrmCustomerCountAction', obj)
      this.counts = res.data
    },
    async getSourceChannel() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    async getChannel(obj){
      this.loadingCC = true
      // let res = await this.$store.dispatch('analysisCrmChannelAction', obj)
      let res = await this.$store.dispatch('analysisCrmCustomerChannelAction', obj)
      this.channelData = res.data
      this.loadingCC = false
    },
    async getAchievementChannel(obj){
      this.loadingAC = true
      let res = await this.$store.dispatch('analysisCrmCustomerAchievementChannelAction', obj)
      this.parent_channel = res.data.parent_channel
      this.sub_channel = res.data.sub_channel
      this.loadingAC = false
    },
    async getChannelSubChannel(obj){
      this.loadingSC = true
      let res = await this.$store.dispatch('analysisCrmChannelSubChannelAction', obj)
      this.channelSCData = res.data
      this.loadingSC = false
    },
    async getChannelIndexStatis(obj){
      this.loadingIS = true
      let res = await this.$store.dispatch('analysisCrmCustomerSubChannelAction', obj)
      this.channelISData = res.data
      this.loadingIS = false
    },
    async getChannelSubChannelStatis(obj){
      this.loadingSCS = true
      let res = await this.$store.dispatch('analysisCrmChannelSubChannelStatisAction', obj)
      this.channelSCSData = res.data
      this.loadingSCS = false
    },
    async getStaff(val){
        let obj = {q:val}
        let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
        this.staffList = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen();
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    },
    closeSearch() {
      this.visible = false
    },
    reset(){
      this.paramsData.channel_id = undefined
      this.channelsId = []
      this.paramsData.studio_id = undefined
      this.paramsData.created_by = undefined
    },
    handleSearch(val){
        this.getStaff(val)
    },
  }
}
</script>

