var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":""}},[_c('div',{staticStyle:{"height":"600px","overflow-y":"auto"}},[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-sankey',{attrs:{"judge-width":"","data":_vm.chartData,"settings":_vm.chartSettings,"grid":{right: '5%'},"height":_vm.wHeight,"title":{
                 text:("客户分布桑基图 （" + (_vm.sankey_title?_vm.sankey_title:'0') + "）"),
                 subtext: _vm.start_date==_vm.end_date?("" + _vm.start_date):(_vm.start_date + " ~ " + _vm.end_date),
                 textStyle: {
                     fontSize: 15,
                 },
                 subtextStyle: {
                     fontSize: 12.5
                 }
             },"extend":{
                 series:{
                     type:'sankey',
                     lineStyle:{
                         show: true,
                         width: 1,
                         opacity: 0.2,
                         curveness: 0.6,
                         type: 'solid',
                         color: 'source',
                     },                     
                     left: '5%',
                     top: '10%',
                     right: '10%',
                     bottom: '5%',
                     nodeWidth:20 ,
                     nodeGap: 10,
                     nodeAlign: 'justify',
                     layoutIterations: 0,
                     orient: 'horizontal',
                     draggable:true,
                     focusNodeAdjacency: false,
                     label: {
                         show: true,
                         position: 'right',
                         fontFamily: 'Arial',
                         margin: 8,
                         normal: {
                             fontSize:10.5,
                             formatter: function (o){
                                 return this$1.handleFormatter(o)
                             }
                         }
                     },
                     itemStyle: {
                         borderWidth: 0,
                         borderColor: '#ccc'
                     },                       
                     lineStyle: {
                         show: true,
                         width: 1,
                         opacity: 0.2,
                         curveness: 0.6,
                         type: 'solid',
                         color: 'source',
                     }
                 }
             }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }